import { media } from '~theme/breakpoints'

export const main = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}

export const wrapper = {
  py: 0,
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '250px 1fr',
  minHeight: '100vh',
  [media.tablet]: {
    display: 'block',
  },
}

export const content = {
  variant: 'styles.Container',
  backgroundColor: 'background',
  position: 'relative',
  maxWidth: 768,
  py: 8,
  px: 4,
  [media.tablet]: {
    py: 4,
    px: 4
  },
}