/** @jsx jsx */
import { jsx, Box} from 'theme-ui'

import * as styles from "./styles";
import { Menu } from "gatsby-theme-docz/src/components/Icons";
import {Logo} from "gatsby-theme-docz/src/components/Logo";

export const Header = props => {
  const { onOpen } = props

  return (
  	<div sx={styles.wrapper} data-testid="header">
  		<Logo />
		<button sx={styles.menuButton} onClick={onOpen}>
			<Menu size={25} />
		</button>
	</div>
  )
}