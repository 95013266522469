import * as mixins from '~utils/mixins'
import { media } from '~theme/breakpoints'

export const wrapper = {
  bg: '#fff',
  zIndex: 1,
  borderBottom: t => `1px solid ${t.colors.border}`,
  display: 'none',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem 32px',
  [media.tablet]: {
    display: 'flex',
  },
}

export const menuButton = {
  ...mixins.ghostButton,
  color: 'header.text',
  opacity: 0.5,
  cursor: 'pointer',
  width: '25px',
  height: '25px'
}